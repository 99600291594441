import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ActionButton from "../components/actionButton"

const Termine = ({data}) => {

  return (
    <Layout title="Termine" slogan="Bevorstehende Veranstaltungen" headerImage={data.image1.childImageSharp.fluid}> 
        <div>
          <p style={{lineHeight: '1'}}>
          {/* <li className="formular-download-link formular-download-link--disabled" style={{fontSize: '1em'}}>Aufgrund der Covid-19 Massnahmen gibt es keine öffentlichen Termine.</li> */}
          {/* <p style={{lineHeight: '3'}}>
            <b>12.02.2020, 14.00 Uhr</b> Plauschgruppe im Haus
            <br/>
            <b>24.02.2020, 10.00 Uhr</b> Schuhverkauf */}
          </p>
          <ul style={{margin: '0'}}>
            <ActionButton type="icon-pdf" address="/download/Spielnachmittage.pdf" text="Spielnachmittage 2025" />
          </ul>
        </div>
    </Layout>
  ) 
}

export default Termine

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`